.content_wrap {
    padding: 32px 43px;
}


.success_content_wrap {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer_wrap {
    display: flex;
    justify-content: center;
}

.accept_btn {
    padding: 8px 16px;
}


.decline_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0A4042 !important;
}

.wix_btn:hover {
  background: #FCFDFF !important;
  color: #003233 !important;
}

.title {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: #121417;
    margin: 4px 0px;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #121417;
    margin-bottom: 30px;
}

.loader_wrap{
    min-height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    margin: 10px;
    font-size: 7px;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #000000;
    background: -moz-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -webkit-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -o-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -ms-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: linear-gradient(to right, #000000 10%, rgba(0,0,0, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #000000;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }