.label_container{
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #202223;
    margin-bottom: 3px;
    width: 100%;
    align-items: center;
}