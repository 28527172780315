.input_wrap {
    margin-bottom: 16px;
    position: relative;/* Handler overlay */
    z-index: 1; /* Handler overlay */
}

.right_element{
    right:20px !important;
}

.input_wrap :global(.Polaris-TextField__Spinner){
    visibility: visible;
}

.help_text {
    /* margin-left: 28px; */
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
}