.switch_on {
    background-color: #007F60 !important;
}

.switch_off {
    background-color: #5C5F62 !important;
}

.react_switch_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 32.9px;
    height: 21px;
    background: white;
    border-radius: 100px;
    position: relative;
    border: 1px solid #e4e4e4;
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}

.react_switch_label .react_switch_button {
    content: '';
    position: absolute;
    top: 1.5px;
    left: 1.29px;
    width: 16px;
    height: 16.5px;
    border-radius: 45px;
    transition: 0.7s;
    background: whitesmoke;
    /* box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05); */
}

.react_switch_checkbox:checked+.react_switch_label .react_switch_button {
    left: calc(100% - 1.29px);
    transform: translateX(-100%);
}

.react_switch_checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.chakra_switch span:nth-child(2) {
    width: 32px !important;
    padding: 0px !important;
}

.chakra_switch[data-checked] span:nth-child(2) {
    background-color: #559D72 !important;
}

.chakra_switch span:nth-child(1) {
    width: 18px;
    height: 18px;
    left: 0px;
    top: 0px;
    position: absolute;
}

.chakra_switch[data-checked] span:nth-child(1) {
    left: 2px !important;
}

.chakra_switch span {
    border: solid 1px #6A7381;
}
