.tag_container {
    height: auto;
}

.tag_container input[type="text"] {
    margin-bottom: 0;
}

.populated input[type="text"] {
    border: none;
}

.tag_input_wrap{
    margin: 12px 0px 16px 0px;
}

.tag_input{
    font-size: 14px !important;
}