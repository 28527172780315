.dropdown_button {
    width: 100%;
}

.dropdown_button:hover {
    background: #F7FAFC;
}

.dropdown_wrap {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
    margin-left: 12px;
}

.block_button {
    margin-left: 3px;
}

.dropdown_title {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
}

.menulist_wrap {
    min-width: 298px !important;
}

.dropdown_options_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option_label {
    margin-left: 8px;
    font-size: 14px;
    color: #000000;
}

.option_icon {
    width: 18px;
    height: 18px;
}

.options_title {
    color: #000000;
    font-size: .75rem !important;
    font-weight: 600 !important;
}