.right_container {
    display: flex;
}

.help_button {
    font-size: 16px !important;
    width: 118px !important;
    height: 36px !important;
    font-weight: 500 !important;
}


.button {
    font-size: 16px !important;
    height: 36px !important;
    font-weight: 500 !important;
}

.save_button {
    width: 69px !important;
}

.save_and_publish_button {
    width: 166px !important;
}


.save_wrap {
    padding: 0px 10px 0px 0px;
    display: flex;
    gap: 10px;
}

.success_toast {
    background: #C6F6D5;
}

.error_toast {
    background: #FED7D7;
}

.toast_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    font-size: 16px;
    color: #2D3748;
    height: 48px;
    left: 20px;
    top: 260px;
    border-radius: 4px;
    width: 400px;
}

.toast_content {
    display: flex;
    margin-top: 1px;
}

.toast_content svg {
    margin-right: 10px;
}

.toast_close {
    margin-top: 1px;
    cursor: pointer;
    margin-right: -2px;
}

.special_width {
    width: 300px
}

.main_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: fixed;
    width: 100%;
    height: 56px;
    left: 0px;
    top: 0px;
    /* z-index: 999; */
    background: #ffffff;
    box-shadow: 0 2px 1px #0000000d, 0 0 1px #42474c73;

    justify-content: space-between;
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin: 2px 0px */
}

.header_left_section {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.header_switch_container {
    display: flex;
    padding: 7%;
    border-right: 1px solid;
    border-color: #DFE3E8;
}

.header_right_section {
    padding-right: 55px;
    display: flex;
    align-items: center;
}

.title_text {
    margin-left: 52px;
    font-size: 14px;
    margin-top: -3px;
}

.exit_button_container:hover+div.title_text {
    opacity: 0 !important;
    transform: translateX(0.5rem)
}

.exit_button_container {
    display: contents
}

.nav_view_button button {
    border: none;
    padding: 5px;
    margin: 10px;
    box-shadow: none;
}

.nav_view_button button svg {
    fill: #5c5f62;
}

.nav_view_button button:disabled svg.action_button {
    opacity: 0.4;
}

.nav_view_button button:hover,
.nav_view_button button:hover path {
    background-color: rgb(219, 219, 219);
    fill: rgb(24, 24, 24);
}

.undo_redo_btn {
    display: flex;
    padding: 3%;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #DFE3E8;
}

.undo_redo_btn .nav_view_button button {
    margin: 0;
}

.nav_view_button button {
    background-color: transparent !important;
}

.nav_view_button button:hover {
    background-color: transparent !important;
}