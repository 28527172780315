.layout_button {
    margin-right: 12px;
    height: 56px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.layout_button> img{
    max-width: none ;
}

.underline {
    border-bottom: 2px solid;
}
.margin {
    margin-bottom: 2px;
}

.button_container button {
    border: none;
    padding: 5px;
    margin: 10px;
    box-shadow: none;
}

.button_container button:hover {
    background-color: rgb(219, 219, 219);
}

.button_container button:hover path {
    fill: rgb(24, 24, 24);
}
