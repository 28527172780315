.exit_button {
  display: flex;
  padding: 17.5px 10px;
  cursor: pointer;
  position: fixed;
  align-items: center;
}

.exit_span {
  position: relative;
  left: 0px;
  padding-left: 10px;
  font-family: Inter;
  font-weight: 550;
  line-height: 20px;
}

.exit_button:hover .exit_button_icon svg {
  fill: #202223;
}

.font_work_sans {
  font-family: 'Work Sans';
}