.input_wrap {
    margin-bottom: 16px;
    position: relative; /* Fixed overlay issue with popover */
    z-index: 1; /* Fixed overlay issue with popover */
}

.input_wrap label{
    font-size: 14px;
}

.select_wrap {
    font-size: 14px !important;
}

.help_text {
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
    margin-top: 4px;
}

.help_text>ul {
    margin-bottom: 6px;
    padding-left: 25px;
}

.help_text a:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.help_text>div {
    margin-bottom: 7px;
}