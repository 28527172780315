
.add_button {
    margin-bottom: 10px;
}

.remove_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.remove_button svg {
    fill: var(--p-text-critical) !important;
    cursor: pointer;
}

.remove_button>span {
    font-size: 15px;
    font-weight: 600;
    margin: 0 !important;
}