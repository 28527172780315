.heading_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading_container h2 {
    margin: 0px !important;
    line-height: 24px !important;
    font-size: 16px !important;
    font-weight: 600;
}

.header_helper_link {
    margin-top: 10px;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    color: var(--p-interactive);
    cursor: pointer;
}

.header_helper_link:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.header_helper_link.wix {
    text-decoration: none;
}

.header_helper_link.wix:hover {
    text-decoration: underline;
    color: var(--p-interactive-hovered) !important;
}