.editor_wrap {
    margin-top: 16px;
    max-width: 300px;
    width: 100%;
}

.editor_field {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    word-break: break-word;
}