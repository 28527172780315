
.help_text {
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
    margin-top: -15px;
    margin-bottom: 7px;
}

.help_text p {
    margin-bottom: 0px;
}

.help_text a:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}