.badge_complete {
    background: #38A169 !important;
    color: white !important;
}

.badge_complete:hover {
    color: #38A169 !important;
    background: white !important;
    border: 1px solid #38A169 !important;
    cursor: default;
}

.badge_incomplete {
    background: #718096 !important;
    color: white !important;
}

.badge_incomplete:hover {
    background: white !important;
    color: #718096 !important;
    border: 1px solid #718096 !important;
    cursor: default;
}