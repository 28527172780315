.popover_title {
    padding: 16px 16px 0px 16px;
    font-size: 0.75rem;
    font-weight: 600;
    color: #6d7175;
}

.popover_list_container {
    align-items: stretch;
    border-top: 1px solid #DFE3E8;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.popover_scrollable {
    width: 310px;
    max-height: calc(100vh - 8px - 298px);
    border-bottom-left-radius: var(--p-border-radius-2);
    border-bottom-right-radius: var(--p-border-radius-2);
    overflow-y: scroll;
}

.popover_scrollable .Polaris-Listbox {
    padding: 8px !important;
}

.popover_scrollable .Polaris-Listbox-TextOption {
    padding: 0px !important;
    margin: 0px !important;
}

.popover_scrollable .Polaris-Listbox-TextOption__Content {
    padding: 8px 8px !important;
    display: block !important;
    font-size: 14px !important;
}

.shopify-popover .add-section-title:hover {
    background-color: #e4e5e7 !important;
    color: var(--p-icon-hovered) !important;
}

.shopify-popover .add-section-title:hover svg {
    fill: var(--p-text) !important;
}

.shopify-popover .add-section-title:hover>.Polaris-Button>span {
    color: var(--p-icon-hovered);
}

.shopify-popover .add-section-title .Polaris-Icon {
    width: 24px;
    height: 24px;
    margin-right: 2px;
}

.shopify-popover .add-section-title .Polaris-Button__Content {
    font-weight: 400;
    color: var(--p-interactive);
}

.shopify-popover .add-section-title .Polaris-Button {
    padding: 10px 14px
}

.activator-container>.Polaris-Button>span {
    justify-content: flex-start;
    color: #4C78C8;
}

.activator-container>.Polaris-Button svg {
    fill: var(--p-interactive);
}

.activator-container>.Polaris-Button {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-left: 8px;
    background: transparent;
}

.activator-container>.Polaris-Button:hover {
    background-color: #f1f2f4;
}

.list-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: -3px;
}