.subheader_container {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    line-height: 1rem;
}

.help_text {
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
    margin-top: 4px;
    margin-bottom: 7px;
}

.help_text a:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}