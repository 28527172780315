/* Content css */


.header_wrap {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.header_wrap_b {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
}

.header_wrap_d {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
}

.header_text {
    font-weight: 500;
    font-size: 28px;
    color: #121417;
    line-height: 33px;
    margin-bottom: 4px;
}

.header_text_d {
    font-weight: 500;
    font-size: 28px;
    color: #121417;
    line-height: 33px;
    margin-bottom: 8px;
}

.header_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #121417;
}

.body_wrap {
    display: flex;
    justify-content: space-between;
}

.body_wrap_d {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.onboard_image {
    width: 393px;
    height: 242px;
}

.onboard_image_f {
    width: 393px;
    height: 242px;
}

.footer_wrap {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.accept_btn {
    padding: 8px 16px;
    /* width: 96px; */
    height: 36px;
}



.decline_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0A4042 !important;
}

.decline_btn:hover{
    background: #2C696A !important;
    color: white !important;
}

.wix_btn:hover{
    background: #FCFDFF !important;
    color: #003233 !important;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #757575;
    margin-bottom: 4px;
}

.title_wrap {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #121417;
    margin-right: 5px;
}

.title_container {
    display: flex;
    margin-bottom: 8px;
}

.right_element {
    /* right: 20px !important; */
}

.number_input {
    font-size: 14px !important;
}

.input_button {
    margin-bottom: 28px;
    max-width: 76px;
}

.radio_button {
    gap: 9px !important;
    margin-top: 10px;
}

.radio_button span {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #121417;
}

.discount_field {
    display: flex;
    align-items: baseline;
    margin-top: 12px;
}

.title_container svg {
    margin-right: 4px;
}

.discount_field_input {
    max-width: 76px;
}

.banner_wrap {
    display: flex;
    margin-top: 16px;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0px 6px 12px rgba(106, 115, 129, 0.16), 0px 3px 8px rgba(87, 102, 117, 0.06);
    border-radius: 4px;
    border-left: 4px solid #4B82BD;
    padding: 12px 12px 14px 8px;
}

.banner_icon {
    margin-right: 8px;
    margin-top: 2px;
}

.banner_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #121417;
    margin-bottom: 4px;
}

.banner_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #121417;
}

.help_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6A7381;
}

.center_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
}

.body_wrap iframe {
    border-radius: 5px;
}

.body_wrap_d iframe {
    border-radius: 5px;
}

.bold {
    font-weight: 600;
}