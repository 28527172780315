.drop_upload_image_box {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px 20px;
    height: 100%;
}

.drop_upload_image_box p {
    padding: 6px 8px;
    font-weight: bold;
    color: #4D81B4 !important;
    background: #E2EFFC;
    border-radius: 8px;
    margin-bottom: 0px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6d7175;
}

.image_container {
    padding-inline-start: 0px !important;
}

.group_container {
    height: 120px;
    width: 120px !important;
    background: #fff;
}

.upload_button {
    background: #e2effc;
    border-radius: 8px;
    margin-bottom: 0;
    padding: 6px 8px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: #6d7175;
    font-weight: 400;
    text-align: center;
}

.input_container {
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0 !important;
}