.wix-popover .add-section-title-link {
    height: 36px;
    border-radius: 0.25rem;
    color: #2C696A !important;
}

.wix-popover .add-section-title:hover {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.wix-popover .add-section-title:hover path {
    fill: #ffffff!important;
}

.wix-modal .wix-primary-button {
    background-color: #000000 !important;
    color: #FFFFFF;
}

.wix-modal .wix-primary-button:hover {
    background-color: rgba(0, 0, 0, 0.64) !important;
    color: #FFFFFF;
}

.primary-button-dander {
    background-color: #EA3323 !important;
}

.primary-button-dander:hover {
    background-color: #C2291B !important;
}

.Polaris-Button--primary {
    background-color: rgb(26, 26, 26) !important;
}