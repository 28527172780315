#quick_action_bar {
    display: flex;
    justify-content: space-between;
    border-bottom: none !important;
    align-items: center;
    margin-top: 5px;
}

.header_switch_container {
    display: flex;
    padding: 14px 24px;
    border-left: 1px solid;
    border-color: #DFE3E8;
}

.header_switch_container .switch_span {
    padding-right: 16px;
    line-height: 16px;
    color: #121417;
}

.nav_view_button button {
    border: none;
    padding: 5px;
    margin: 10px;
    box-shadow: none;
    color: var(--Neutral-900, #121417)
}

.nav_view_button button svg {
    fill: #5c5f62;
}

.nav_view_button button:disabled svg.action_button {
    opacity: 0.4;
}

.nav_view_button button:hover,
.nav_view_button button:hover path {
    background: rgb(219, 219, 219);
    fill: rgb(24, 24, 24);
}

.undo_redo_btn .nav_view_button button {
    margin: 0;
}

.nav_view_button button {
    background: transparent !important;
    box-shadow: none !important;
}

.nav_view_button button:hover { 
    background: transparent !important;
    color: var(--Neutral-900, #121417) !important;
    text-decoration: underline;
}

.main_text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 15px;
    color: #363C45;
    margin-left: 13px;
    margin-right: 13px;
}