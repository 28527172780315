.wix_button {
    font-weight: 400 !important;
    line-height: 16px !important;
    font-size: 14px !important;
    min-height: 32px !important;
    white-space: break-spaces !important;
}


.disabled_button {
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.36) !important;
    opacity: 1 !important;
}

.disabled_button:hover {
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.36) !important;
}